.breadcrumb-icon {
  position: absolute;
  top: -20px;
  right: 19px;
}
.breadcrumb{
  list-style: none;
  margin: 0;
  padding: 0px;
  display: flex;
}
.breadcrumb-item {
  padding: 0 10px 0 0;
  a {
    text-decoration: none;
    color: $textPrimary;
    font-size: 14px;
    &:hover{
      color:$primary
    }
  }
}
