/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0 }
$primary:#2a3447;
@font-face {
    font-family: "regular";
    src: url(assets/fonts/AvenirNextLTPro-Regular.ttf);
  }
  
  @font-face {
    font-family: "medium";
    src: url(assets/fonts/AvenirNextLTPro-Demi.ttf);
  }
  
  @font-face {
    font-family: "bold";
    src: url(assets/fonts/AvenirNextLTPro-Bold.ttf);
  }
  
  *  {
    font-family: "regular" !important;
    font-weight: 400;
  }

  .material-icons {
    font-family: 'Material Icons' !important;
  }

 